import React from 'react';
import data1 from '../../../data/home1/projects/lancamentos';
function Projects() {
    return (
        <section className="tc-projects-style1 mb-50 mt-50">
            <div className="container">
                <div className="tabs-links mb-50">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="title">
                                <h2 className="fsz-45"> Nossos Lançamentos </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="projects">
                    <div className="tab-content" id="pills-tabContent">
                        <div
                            className="tab-pane fade show active"
                            id="pills-proj1"
                            role="tabpanel"
                            aria-labelledby="pills-proj1-tab"
                        >
                            <div className="projects-content float_box_container">
                                <div className="projects-slider">
                                    <div className="swiper-wrapper">
                                        {data1.map((item, i) => (
                                            <div key={i} className="swiper-slide">
                                                <div className="project-card">
                                                    <a
                                                        href={item.img}
                                                        data-fancybox="proj"
                                                        className="img"
                                                    >
                                                        <img src={item.img} alt="" className="img-cover" />
                                                    </a>
                                                    <div className="info">
                                                        {/* <div className="tags">
                              <a href="#"> {item.sub1} </a>
                              <a href="#"> {item.sub2} </a>
                            </div> */}
                                                        <h3 className="title">
                                                            <a href="#"> {item.title} </a>
                                                        </h3>
                                                        {/* <div className="text">{item.desc}</div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="float-cursor float_box">
                                    Segure <br /> e arraste
                                </div>
                            </div>
                        </div>
                        <div
                            className="tab-pane fade"
                            id="pills-proj2"
                            role="tabpanel"
                            aria-labelledby="pills-proj2-tab"
                        >
                            <div className="projects-content float_box_container">
                                <div className="projects-slider">
                                    <div className="swiper-wrapper">
                                        {data1.map((item, i) => (
                                            <div key={i} className="swiper-slide">
                                                <div className="project-card">
                                                    <a
                                                        className="img"
                                                    >
                                                        <img src={item.img} alt="" className="img-cover" />
                                                    </a>
                                                    <div className="info">
                                                        {/* <div className="tags">
                              <a href="#"> {item.sub1} </a>
                              <a href="#"> {item.sub2} </a>
                            </div> */}
                                                        <h3 className="title">
                                                            <a href="#"> {item.title} </a>
                                                        </h3>
                                                        <div className="text">{item.desc}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="float-cursor float_box">
                                    Hold <br /> and Drag
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Projects;
