import React from 'react';

function Process() {
  return (
    <section className="tc-process-style1">
      <div className="container">
        <div className="content">
          <div className="row">

            <div className="col-lg-6">
              <h4 className='service-subtitle text-center mb-3'>Perlare</h4>
              <div className="title mb-50 text-center">
                <h2 className="fsz-45"> Expressão Aplicada ao Ambiente </h2>
              </div>
              <div className="wow">
                <p>Alfaiataria de alumínio e vidro, assim é a Perlare. Executamos projetos totalmente personalizados, desde a escolha dos acabamentos, os modelos de perfis que mais condizem com a harmonia do ambiente e uma gama de puxadores para atender todas as necessidades do cliente, visando além da estética a funcionalidade e ergonomia do produto. Nossa prioridade é fazer com que nossos clientes tenham em suas residências um produto com excelente acabamento, alta durabilidade e totalmente funcional.</p>
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1">
              <div className="info">
                <div
                  className="accordion wow fadeInUp slow"
                  id="accordionProcess"
                >
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        <span className="num"> 1 / </span>
                        <h3> SIENA / ALBEROBELLO </h3>
                      </button>
                    </div>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#accordionProcess"
                    >
                      <div className="accordion-body">
                        <div className="text">
                          <p>
                            PIETRA
                            <br />CROSTINI
                            <br />CIOCCOLATO<br />
                            <br />GELATTO
                            <br />ALGHE
                            <br />NOTTE
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                      >
                        <span className="num"> 2 / </span>
                        <h3> FLORENÇA / TOSCANA </h3>
                      </button>
                    </div>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionProcess"
                    >
                      <div className="accordion-body">
                        <div className="text">
                          <p>
                            ANANAS
                            <br />TIRAMISU
                            <br />CEMENTO<br />
                            <br />CIELO
                            <br />PAGLIA
                            <br />BASÍLICO
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                      >
                        <span className="num"> 3 / </span> <h3> VENEZA / SICÍLIA </h3>
                      </button>
                    </div>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionProcess"
                    >
                      <div className="accordion-body">
                        <div className="text">
                          <p>
                            NUVOLOSO
                            <br />CARPESSE
                            <br />ROCCIA<br />
                            <br />PANNA COTTA
                            <br />GRIGIO
                            <br />TEMPESTA
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Process;
