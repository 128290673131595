import React from 'react';


function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark tc-navbar-style1 section-padding-x">
      <div className="container-fluid content">
        <a className="navbar-brand" href="#">
          <img src="/home1/assets/img/brand_white.png" alt="" className="logo" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link" aria-current="page" href="/">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/sobre">
                Sobre
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/lancamentos">
                Lançamentos
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/produtos">
                Produtos
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/contato">
                Contato
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://configurador.perlare.com/Login.aspx" target="_blank">
                Configure seu Produto
              </a>
            </li>
          </ul>
          <div className="nav-side">
            <a
              href="https://api.whatsapp.com/send?phone=5554999746664"
              target='_blank'
              className="butn hover-bg-black text-capitalize bg-orange1 rounded-pill text-white"
            >
              <span> Fale Conosco </span>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
