import React from 'react';

function Services() {
  return (
    <section className="tc-services-style1">
      <div className="content section-padding section-padding-x">
        <div className="container">
          <div className="title mb-80 text-center">
            <p className="color-666 text-uppercase wow"> nossas linhas </p>
          </div>
          <div className="services">
            <div className="row">
              <div className="col-lg-4">
                <a
                  href="javascript:void(0)"
                  className="service-card wow fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <div className="icon">
                    <i className="la la-hard-hat"></i>
                  </div>
                  <h5 className="fsz-24 mb-20"> Arquitetura </h5>
                  <div className="img">
                    <img
                      src="/home1/assets/img/services/ser1.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                    Produtos desenvolvidos para o ramo da construção civil e arquitetura, as divisórias de ambiente deslizantes e as portas pivotantes se encaixam em qualquer ambiente.
                  </div>
                  
                </a>
              </div>
              <div className="col-lg-4">
                <a
                  href="javascript:void(0)"
                  className="service-card mt-150 wow fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <div className="icon">
                    <i className="la la-bed"></i>
                  </div>
                  <h5 className="fsz-24 mb-20">Indústria Moveleira</h5>
                  <div className="img">
                    <img
                      src="/home1/assets/img/services/ser2.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                    Nossos componentes para móveis, como portas de giro, basculantes, frentes de gaveta, portas deslizantes e puxadores, são especialmente desenvolvidos para o ramo moveleiro com o intuito completar seu projeto com o que a de melhor em acabamentos e funcionalidade.
                  </div>
                  
                </a>
              </div>
              <div className="col-lg-4">
                <a
                  href="javascript:void(0)"
                  className="service-card wow fadeInUp"
                  data-wow-delay="0.6s"
                >
                  <div className="icon">
                    <i className="la la-bezier-curve"></i>
                  </div>
                  
                  <h5 className="fsz-24 mb-20"> Decoração </h5>
                  <div className="img">
                    <img
                      src="/home1/assets/img/services/ser3.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                    Para agregar mais design possuímos uma linha de produtos para deixar seu projeto ainda mais arrojado e elegante, conheça nossa linha de estantes metálicas, painéis em espelho com ilumição em LED e nosso Painel Cinema.
                  </div>
                  
                </a>
              </div>
            </div>
          </div>
          <div className="text-center">
            <a
              href="https://api.whatsapp.com/send?phone=5554999746664"
              target='_blank'
              className="butn rounded-pill mt-80 hover-bg-black bg-orange1 text-white"
            >
              <span>
                Solicite um Orçamento Agora
                <i className="small ms-1 ti-arrow-top-right"></i>
              </span>
            </a>
          </div>
        </div>
      </div>

    </section>
  );
}

export default Services;
