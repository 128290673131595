import React from 'react';
import Loader from '../../components/common/Loader';
import Footer from '../../components/home1/Footer';
import Menu from '../../components/innerpage/Menu';
import Navbar from '../../components/innerpage/Navbar';
import StartButton from '../../components/home5_residence/StartButton';
import Header from '../../components/innerpage/services/Header';
import Chat from '../../components/home1/Chat';
import Services from '../../components/innerpage/services/Services';
import Process from '../../components/innerpage/services/Process';
import Testimonials from '../../components/innerpage/services/Testimonials';
import Projects from '../../components/innerpage/services/Projects';
import { Helmet } from 'react-helmet';

function ServicesPage() {
  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href="/innerpages/assets/css/innerpages.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/home1/assets/css/home_1_style.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/common/assets/css/common_style.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/home_preview/assets/css/home_preview_style.css"
        />
        <script src="/common/assets/js/common_js.js"></script>
        <script src="/home1/assets/js/home_1_scripts.js"></script>
        <script src="/innerpages/assets/js/innerpages.js"></script>
      </Helmet>
      <body className="inner-pages-style1 services-pg-style1 home-style1 home-preview">
        <Loader />
        <Menu />
        <div className="smooth-scroll-content" id="scrollsmoother-container">
          <Navbar />
          <Header />
          <main>
            <div className='container'>
              <Projects />
              <Process />
            </div>

            <Chat />
          </main>
          <Footer />
        </div>
        <StartButton />
      </body>
    </>
  );
}

export default ServicesPage;
