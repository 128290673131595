import React from 'react';

function Footer() {
  return (
    <footer className="tc-footer-style1">
      <div className="container">
        <div className="top-content section-padding">
          <div className="row gx-0">
            <div className="col-lg-4">
              <div className="info-side">
                <img src="/home1/assets/img/logo-escrita.png" width={ 300 } className='text-center mb-50' />
                <div className="text fsz-24 color-333 lh-3 fw-600">
                  Somos uma empresa especializada em soluções de alumínio e vidro para o ramo moveleiro, construção civil, arquitetura e decoração.
                </div>
                <div className="foot-social mt-50">
                  <a href="https://www.facebook.com/perlareoficial/" target='_blank'>
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="https://www.instagram.com/perlareoficial/" target='_blank'>
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href="https://www.youtube.com/@perlare7508" target='_blank'>
                    <i className="fab fa-youtube"></i>
                  </a>
                  <a href="https://api.whatsapp.com/send?phone=5554999746664" target='_blank'>
                    <i className="fab fa-whatsapp"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 offset-lg-2">
              <div className="branch-card">
                <h5 className="mb-20 mt-5 mt-lg-0 fw-600"> Menu </h5>
                <ul className="footer-links">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/sobre"> Sobre </a>
                  </li>
                  <li>
                    <a href="/produtos"> Produtos </a>
                  </li>
                  <li>
                    <a href="/lancamentos"> Lançamentos </a>
                  </li>
                  <li>
                    <a href="/contato"> Contato </a>
                  </li>
                  <li>
                    <a href="https://configurador.perlare.com/Login.aspx" target='_blank'> Configure seu produto </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="branch-card">
                <h5 className="mb-20 mt-5 mt-lg-0 fw-600"> Contato </h5>
                <ul className="footer-links">
                  <li>
                    <a href="https://maps.app.goo.gl/Cr8HFe6reJ3gtqne7" target='_blank'>R. Ângelo Luchese, 90 - Bento Gonçalves, RS</a>
                  </li>
                  <li>
                    <a href="mailto:perlare@perlare.com"> perlare@perlare.com </a>
                  </li>
                  <li>
                    <a href="tel:+555421029191"> (54) 2102-9191 </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/perlareoficial/" target='_blank'> @perlareoficial </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="foot">
          <div className="row">
            <div className="col-lg-6">
              <p className="fsz-13">© 2025 Perlare. Todos os direitos reservados</p>
            </div>
            <div className="col-lg-6">
              <div className="foot-links mt-4 mt-lg-0">
                <a href="/"> Home </a>
                <a href="/sobre"> Sobre </a>
                <a href="/lancamentos"> Lançamentos </a>
                <a href="/produtos"> Produtos </a>
                <a href="/contato"> Contato </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
