import React from 'react';

function ContactInfo() {
  return (
    <section className="tc-contact-info-style1 mb-50">
      <div className="container">
        <div className="row mt-50">
        <div className="col-lg-8">
            <div className="map-card wow zoomIn slow" data-wow-delay="0.2s">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3483.929761231464!2d-51.48032149999999!3d-29.166742!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x951c230ce40d2c3f%3A0x152c21e745480f75!2sPerlare!5e0!3m2!1spt-BR!2sbr!4v1738756882903!5m2!1spt-BR!2sbr"
                width="100%"
                height="500px"
                style={{ border: 0 }}
                allowFullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="info-cards ms-5">
              <div className="row">
                <div className="col-lg-12">
                  <div className="item mt-50">
                    <h6 className="fsz-24 mb-15 fw-bold"> Bento Gonçalves/RS </h6>
                    <ul className="color-666 lh-6">
                      <li>
                        <a href="https://maps.app.goo.gl/Cr8HFe6reJ3gtqne7" target='_blank'> R. Ângelo Luchese, 90 - Bento Gonçalves, RS </a>
                      </li>
                      <li>
                        <a href="mailto:perlare@perlare.com"> perlare@perlare.com </a>
                      </li>
                      <li>
                        <a href="tel:+555421029191"> (54) 2102-9191</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="social-icons mt-50 text-lg-start ms-5">
              <a href="https://www.facebook.com/perlareoficial/" target='_blank'>
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="https://www.instagram.com/perlareoficial/" target='_blank'>
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://www.youtube.com/@perlare7508" target='_blank'>
                <i className="fab fa-youtube"></i>
              </a>
              <a href="https://api.whatsapp.com/send?phone=5554999746664" target='_blank'>
                <i className="fab fa-whatsapp"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactInfo;
